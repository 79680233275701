import React from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import {GuestLayout} from "./components/Layout/GuestLayout/GuestLayout";
import {Home} from "./pages/home/Home";


export const useRoutes = (isAuth) => {
  return (
    <GuestLayout>
      <Switch>
        <Route path="/">
          <Home/>
        </Route>
      </Switch>
    </GuestLayout>
  )
}