export const API_URLS = {
  OAUTH: {
    TOKEN: 'oauth/token',
  },
  USERS: {
    CURRENT: 'users/current',
  },
  PUBLIC: {
    DATA: 'public',
  },
  SSE: {
    STREAM: 'sse/public/stream'
  },
};
