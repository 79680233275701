import React from "react";
import {
  Ad, Ae,
  Af,
  Ag,
  Ai,
  Al,
  Am,
  Ao,
  Ar,
  As,
  At,
  Au,
  Aw,
  Az,
  Ba,
  Bb,
  Bd,
  Be,
  Bf,
  Bg,
  Bh,
  Bi,
  Bj,
  Bm,
  Bo,
  Br,
  Bs,
  Bt,
  Bw,
  By,
  Ca,
  Cf,
  Cg, Ch,
  Ci,
  Ck,
  Cl,
  Cm,
  Cn,
  Co,
  Cr,
  Cu,
  Cv,
  Cw,
  Cy,
  Cz,
  De,
  Dj,
  Dk,
  Dm,
  Do,
  Dz,
  Ec,
  Ee,
  Eg,
  Er,
  Es,
  Et,
  Fi,
  Fj,
  Fk,
  Fm,
  Fo,
  Fr,
  Ga, Gb,
  Gd,
  Ge,
  Gg,
  Gh,
  Gi,
  Gl,
  Gm,
  Gn,
  Gq,
  Gr,
  Gt,
  Gu,
  Gw,
  Hk,
  Hn,
  Hr,
  Ht,
  Hu,
  Id,
  Ie,
  Il,
  Im,
  In,
  Iq,
  Ir,
  Is,
  It,
  Je,
  Jm,
  Jo,
  Jp,
  Ke,
  Kg,
  Kh,
  Ki,
  Km, Kn,
  Kp,
  Kr,
  Kw,
  Ky,
  Kz,
  La, Lb, Lc,
  Li, Lk,
  Lr,
  Ls,
  Lt,
  Lu,
  Lv,
  Ly,
  Ma,
  Mc,
  Md,
  Me,
  Mg,
  Mh,
  Mk,
  Ml, Mm,
  Mn,
  Mo, Mp,
  Mq,
  Mr,
  Ms,
  Mt,
  Mu,
  Mv,
  Mw,
  Mx,
  My,
  Mz, Na, Ne, Nf, Ng, Ni, Nl, No, Np, Nr, Nu, Nz, Om, Pa, Pe,
  Pf, Pg, Ph, Pk, Pl, Pn, Pr, Ps, Pt, Pw, Py, Qa, Ro, Rs,
  Ru, Rw, Sa, Sb, Sc, Sd, Se, Sg, Si, Sk, Sl, Sm, Sn, So, Sr, Ss, St,
  Sv, Sx, Sy, Tc,
  Td, Tg, Th, Tj, Tk, Tm, Tn, To, Tr, Tt, Tv, Tw, Tz, Ua, Ug, Us, Uy, Uz, Ve, Vi, Vn, Vu, Ws, Ye, Za, Zm, Zw
} from "react-flags-select";

export const IsoToFlag = (iso) => {
  return (
    <>
        {iso && (
          iso === "AF" ? <Af/> :
          iso === "AX" ? "" :
          iso === "AL" ? <Al/> :
          iso === "DZ" ? <Dz/> :
          iso === "AS" ? <As/> :
          iso === "AD" ? <Ad/> :
          iso === "AO" ? <Ao/> :
          iso === "AI" ? <Ai/> :
          iso === "AQ" ? "" :
          iso === "AG" ? <Ag/> :
          iso === "AR" ? <Ar/> :
          iso === "AM" ? <Am/> :
          iso === "AW" ? <Aw/> :
          iso === "AU" ? <Au/> :
          iso === "AT" ? <At/> :
          iso === "AZ" ? <Az/> :
          iso === "BS" ? <Bs/> :
          iso === "BH" ? <Bh/> :
          iso === "BD" ? <Bd/> :
          iso === "BB" ? <Bb/> :
          iso === "BY" ? <By/> :
          iso === "BE" ? <Be/> :
          iso === "BZ" ? "" :
          iso === "BJ" ? <Bj/> :
          iso === "BM" ? <Bm/> :
          iso === "BT" ? <Bt/> :
          iso === "BO" ? <Bo/> :
          iso === "BQ" ? "" :
          iso === "BA" ? <Ba/> :
          iso === "BW" ? <Bw/> :
          iso === "BV" ? "" :
          iso === "BR" ? <Br/> :
          iso === "IO" ? "" :
          iso === "BN" ? "" :
          iso === "BG" ? <Bg/> :
          iso === "BF" ? <Bf/> :
          iso === "BI" ? <Bi/> :
          iso === "KH" ? <Kh/> :
          iso === "CM" ? <Cm/> :
          iso === "CA" ? <Ca/> :
          iso === "CV" ? <Cv/> :
          iso === "KY" ? <Ky/> :
          iso === "CF" ? <Cf/> :
          iso === "TD" ? <Td/> :
          iso === "CL" ? <Cl/> :
          iso === "CN" ? <Cn/> :
          iso === "CX" ? "" :
          iso === "CC" ? "" :
          iso === "CO" ? <Co/> :
          iso === "KM" ? <Km/> :
          iso === "CG" ? <Cg/> :
          iso === "CK" ? <Ck/> :
          iso === "CR" ? <Cr/> :
          iso === "CI" ? <Ci/> :
          iso === "HR" ? <Hr/> :
          iso === "CU" ? <Cu/> :
          iso === "CW" ? <Cw/> :
          iso === "CY" ? <Cy/> :
          iso === "CZ" ? <Cz/> :
          iso === "DK" ? <Dk/> :
          iso === "DJ" ? <Dj/> :
          iso === "DM" ? <Dm/> :
          iso === "DO" ? <Do/> :
          iso === "EC" ? <Ec/> :
          iso === "EG" ? <Eg/> :
          iso === "SV" ? <Sv/> :
          iso === "GQ" ? <Gq/> :
          iso === "ER" ? <Er/> :
          iso === "EE" ? <Ee/> :
          iso === "ET" ? <Et/> :
          iso === "FK" ? <Fk/> :
          iso === "FO" ? <Fo/> :
          iso === "FJ" ? <Fj/> :
          iso === "FI" ? <Fi/> :
          iso === "FR" ? <Fr/> :
          iso === "GF" ? <Fr/> :
          iso === "PF" ? <Pf/> :
          iso === "TF" ? <Fr/> :
          iso === "GA" ? <Ga/> :
          iso === "GM" ? <Gm/> :
          iso === "GE" ? <Ge/> :
          iso === "DE" ? <De/> :
          iso === "GH" ? <Gh/> :
          iso === "GI" ? <Gi/> :
          iso === "GR" ? <Gr/> :
          iso === "GL" ? <Gl/> :
          iso === "GD" ? <Gd/> :
          iso === "GP" ? "" :
          iso === "GU" ? <Gu/> :
          iso === "GT" ? <Gt/> :
          iso === "GG" ? <Gg/> :
          iso === "GN" ? <Gn/> :
          iso === "GW" ? <Gw/> :
          iso === "GY" ? "" :
          iso === "HT" ? <Ht/> :
          iso === "HM" ? "" :
          iso === "VA" ? "" :
          iso === "HN" ? <Hn/> :
          iso === "HK" ? <Hk/> :
          iso === "HU" ? <Hu/> : //100
          iso === "IS" ? <Is/> :
          iso === "IN" ? <In/> :
          iso === "ID" ? <Id/> :
          iso === "IR" ? <Ir/> :
          iso === "IQ" ? <Iq/> :
          iso === "IE" ? <Ie/> :
          iso === "IM" ? <Im/> :
          iso === "IL" ? <Il/> :
          iso === "IT" ? <It/> :
          iso === "JM" ? <Jm/> :
          iso === "JP" ? <Jp/> :
          iso === "JE" ? <Je/> :
          iso === "JO" ? <Jo/> :
          iso === "KZ" ? <Kz/> :
          iso === "KE" ? <Ke/> :
          iso === "KI" ? <Ki/> :
          iso === "KP" ? <Kp/> :
          iso === "KR" ? <Kr/> :
          iso === "KW" ? <Kw/> :
          iso === "KG" ? <Kg/> :
          iso === "LA" ? <La/> :
          iso === "LV" ? <Lv/> :
          iso === "LB" ? <Lb/> :
          iso === "LS" ? <Ls/> :
          iso === "LR" ? <Lr/> :
          iso === "LY" ? <Ly/> :
          iso === "LI" ? <Li/> :
          iso === "LT" ? <Lt/> :
          iso === "LU" ? <Lu/> :
          iso === "MO" ? <Mo/> :
          iso === "MK" ? <Mk/> :
          iso === "MG" ? <Mg/> :
          iso === "MW" ? <Mw/> :
          iso === "MY" ? <My/> :
          iso === "MV" ? <Mv/> :
          iso === "ML" ? <Ml/> :
          iso === "MT" ? <Mt/> :
          iso === "MH" ? <Mh/> :
          iso === "MQ" ? <Mq/> :
          iso === "MR" ? <Mr/> :
          iso === "MU" ? <Mu/> :
          iso === "YT" ? "" :
          iso === "MX" ? <Mx/> :
          iso === "FM" ? <Fm/> :
          iso === "MD" ? <Md/> :
          iso === "MC" ? <Mc/> :
          iso === "MN" ? <Mn/> :
          iso === "ME" ? <Me/> :
          iso === "MS" ? <Ms/> :
          iso === "MA" ? <Ma/> :
          iso === "MZ" ? <Mz/> :
          iso === "MM" ? <Mm/> :
          iso === "NA" ? <Na/> :
          iso === "NR" ? <Nr/> :
          iso === "NP" ? <Np/> :
          iso === "NL" ? <Nl/> :
          iso === "NC" ? "" :
          iso === "NZ" ? <Nz/> :
          iso === "NI" ? <Ni/> :
          iso === "NE" ? <Ne/> :
          iso === "NG" ? <Ng/> :
          iso === "NU" ? <Nu/> :
          iso === "NF" ? <Nf/> :
          iso === "MP" ? <Mp/> :
          iso === "NO" ? <No/> :
          iso === "OM" ? <Om/> :
          iso === "PK" ? <Pk/> :
          iso === "PW" ? <Pw/> :
          iso === "PS" ? <Ps/> :
          iso === "PA" ? <Pa/> :
          iso === "PG" ? <Pg/> :
          iso === "PY" ? <Py/> :
          iso === "PE" ? <Pe/> :
          iso === "PH" ? <Ph/> :
          iso === "PN" ? <Pn/> :
          iso === "PL" ? <Pl/> :
          iso === "PT" ? <Pt/> :
          iso === "PR" ? <Pr/> :
          iso === "QA" ? <Qa/> :
          iso === "RE" ? "" :
          iso === "RO" ? <Ro/> :
          iso === "RU" ? <Ru/> :
          iso === "RW" ? <Rw/> :
          iso === "BL" ? "" :
          iso === "SH" ? "" :
          iso === "KN" ? <Kn/> :
          iso === "LC" ? <Lc/> :
          iso === "MF" ? "" :
          iso === "PM" ? "" :
          iso === "VC" ? "" :
          iso === "WS" ? <Ws/> :
          iso === "SM" ? <Sm/> :
          iso === "ST" ? <St/> :
          iso === "SA" ? <Sa/> :
          iso === "SN" ? <Sn/> :
          iso === "RS" ? <Rs/> :
          iso === "SC" ? <Sc/> :
          iso === "SL" ? <Sl/> :
          iso === "SG" ? <Sg/> :
          iso === "SX" ? <Sx/> : //200
          iso === "SK" ? <Sk/> :
          iso === "SI" ? <Si/> :
          iso === "SB" ? <Sb/> :
          iso === "SO" ? <So/> :
          iso === "ZA" ? <Za/> :
          iso === "GS" ? "" :
          iso === "SS" ? <Ss/> :
          iso === "ES" ? <Es/> :
          iso === "LK" ? <Lk/> :
          iso === "SD" ? <Sd/> :
          iso === "SR" ? <Sr/> :
          iso === "SJ" ? "" :
          iso === "SE" ? <Se/> :
          iso === "CH" ? <Ch/> :
          iso === "SY" ? <Sy/> :
          iso === "TW" ? <Tw/> :
          iso === "TJ" ? <Tj/> :
          iso === "TZ" ? <Tz/> :
          iso === "TH" ? <Th/> :
          iso === "TL" ? "" :
          iso === "TG" ? <Tg/> :
          iso === "TK" ? <Tk/> :
          iso === "TO" ? <To/> :
          iso === "TT" ? <Tt/> :
          iso === "TN" ? <Tn/> :
          iso === "TR" ? <Tr/> :
          iso === "TM" ? <Tm/> :
          iso === "TC" ? <Tc/> :
          iso === "TV" ? <Tv/> :
          iso === "UG" ? <Ug/> :
          iso === "UA" ? <Ua/> :
          iso === "AE" ? <Ae/> :
          iso === "GB" ? <Gb/> :
          iso === "US" ? <Us/> :
          iso === "UM" ? <Us/> :
          iso === "UY" ? <Uy/> :
          iso === "UZ" ? <Uz/> :
          iso === "VU" ? <Vu/> :
          iso === "VE" ? <Ve/> :
          iso === "VN" ? <Vn/> :
          iso === "VG" ? "" :
          iso === "VI" ? <Vi/> :
          iso === "WF" ? "" :
          iso === "EH" ? "" :
          iso === "YE" ? <Ye/> :
          iso === "ZM" ? <Zm/> :
          iso === "ZW" ? <Zw/> :
          iso === "RKS" ? "" :
          iso === "ANT" ? "" : ""
        )}
    </>
  )
}