import React from "react";
import './TopPanel.css';

export const TopPanel = () => {
  return (
    <div className="topPanel">
      <div className="topPanelMenu">
          <div style={{marginRight: 30}}>EN</div>
          <div>Support</div>
      </div>
    </div>
  )
}