import React, {useState} from 'react';
import moment from "moment";

export const CountdownSmall = () => {
  const [time, setTime] = useState("00H: 00M: 00S");
  const [h, setH] = useState("00");
  const [m, setM] = useState("00");
  const [s, setS] = useState("00");

  const x = setInterval(function () {
    const nowLocal = new Date();
    const now = new Date(nowLocal.toUTCString().slice(0, -4))
    let dueDate;

    if (now.getHours() >= 12) {
      dueDate = moment(now).add(1, 'day').toDate();
      dueDate = moment(dueDate).set({h: 12, m: 0, s: 0}).toDate();
    } else {
      dueDate = moment(now).set({h: 12, m: 0, s: 0}).toDate();
    }

    let diffTime = Math.abs(dueDate - now) / 1000;
    let days = Math.floor(diffTime / 86400);
    diffTime -= days * 86400;
    let hours = Math.floor(diffTime / 3600) % 24;
    diffTime -= hours * 3600;
    let minutes = Math.floor(diffTime / 60) % 60;
    diffTime -= minutes * 60;
    let seconds = diffTime % 60;

    seconds = seconds < 10 ? "0" + seconds : seconds;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    setTime(+hours + "H : " + minutes + "M : " + seconds + "S ");
    setH(hours)
    setM(minutes)
    setS(seconds)

    if (diffTime < 0) {
      clearInterval(x);
      setTime("EXPIRED");
    }
  }, 1000);

  return (
    <div className="lotteryJackPotNextDraw"><div style={{marginRight: 5}}>Next Draw: </div> {time}</div>
  )
}