import React from 'react';
import './GuestLayout.css'
import {TopPanel} from "../../TopPanel/TopPanel";
import {Header} from "../../Header/Header";

export const GuestLayout = ({children}) => {
  return (
    <>
      <TopPanel/>
      <Header/>
      <div className={'guestBody'}>{children}</div>
    </>
  )
}