import React from 'react';
import {useRoutes} from "./routes";
import {BrowserRouter} from 'react-router-dom'
import {useSelector} from "react-redux";

function App() {
  const authInfo = useSelector((state) => ({
    isAuth: state.base.isAuth,
    user: state.base.user
  }));
  const {isAuth} = authInfo;

  const routes = useRoutes(isAuth);
  return (
      <BrowserRouter>
        {routes}
      </BrowserRouter>
  );
}

export default App;
