import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {Button} from "@material-ui/core";

export const OrangeButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#EEA51F",
    '&:hover': {
      backgroundColor: "#EEA51F",
    },
    borderRadius: 24,
    minWidth: 150,
    height: 40
  },
}))(Button);