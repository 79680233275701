import React, {useCallback, useEffect, useRef, useState} from "react";
import './Home.css';
import {toEur} from "../../utils/tools";
import {OrangeButton} from "../../components/TrayectoButton/OrangeButton";
import {client} from "../../services/client";
import {IsoToFlag} from "../../components/IsoToFlag/IsoToFlag";
import {Countdown} from "../../components/Countdown/Countdown";
import {CountdownSmall} from "../../components/CountdownSmall/CountdownSmall";
import {Modal} from "@material-ui/core";

export const Home = () => {
  const [data, setData] = useState();
  const vidRef = useRef(null);
  const [number, setNumber] = useState(1);
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };


  const getData = useCallback(async () => {
    try {
      const response = await client.getPublicGameData();
      setData(response);
      return response;
    } catch (e) {
      console.log(e.message);
    }
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    const sse = client.getStream();
    sse.onmessage = function (event) {
      if (event.data !== "") {
        const msg = JSON.parse(event.data);
        console.log(msg)
        switch (msg.type) {
          case "NUMBER":
            setNumber(msg.value);
            handleOpenModal();
            break;
        }
      }
    }
  }, [])

  return (
    <>
      <div className="nextDrawBox">
        <div className="nextDrawContainer1">
          <div className="nextDrawContainer">
            <div className="nextDrawContainerInner">
              <div className="nextDrawCaption">Next draw happens in</div>
              <div className="nextDrawDescription">The only way to participate in Lucky Dozens game is to be part of our
                co-operation companies that use
                us as game operator.
              </div>
            </div>

            <Countdown/>
          </div>
        </div>
      </div>

      <div className="lotteryJackPotContainer">
        <div>
          <div className="lotteryJackPotCaption">
            Lottery Jackpots
          </div>
          <div className="lotteryJackPotDescription">
            There are currently 2 Jackpots in Lite and Mega Games.
          </div>
          <div className="lotteryJackPotDataContainer">
            <div className="lotteryJackPotData">
              <div className="ballMega"/>
              <div className="lotteryJackPotDataAmount">€ 2,400,000</div>
              <div className="lotteryJackPotName">MEGA Game</div>
              <CountdownSmall/>
              <div>
                <OrangeButton>Learn more</OrangeButton>
              </div>
            </div>
            <div className="lotteryJackPotData">
              <div className="ballLite"/>
              <div className="lotteryJackPotDataAmount">€ 240,000</div>
              <div className="lotteryJackPotName">LITE Game</div>
              <CountdownSmall/>
              <div>
                <OrangeButton>Learn more</OrangeButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="latestResults">
        <div className="latestResultsContainer">
          <div>
            <div className="latestResultsCaption">Latest Lucky Dozens Results</div>
            <div className="latestResultsDescription">
              Here you find the daily number draw for the Lucky Dozens game <br/>
              and see the number of lucky winners every day!
            </div>

            <div className="latestResultsTables">
              <div className="latestResultsTable1">
                <div className="latestResultsTableCaption">Lottery Winning Numbers</div>
                <table className="latestResultsTable1Style">
                  <thead>
                  <tr>
                    <th>Lottery</th>
                    <th>Draw date</th>
                    <th>Daily Number</th>
                    <th>Total Winners</th>
                  </tr>
                  </thead>
                  <tbody>
                  {data && data.winNumbers.map((w, i) => (
                      <tr key={i}>
                        <td style={{display: "flex", alignItems: "center"}}>
                          <div style={{fontSize: 32, marginRight: 6}}>
                            {IsoToFlag("CR")}
                          </div>
                          <div>Lucky Dozens</div>
                        </td>
                        <td>{w.date}</td>
                        <td>
                          <div className="ball">{w.number}</div>
                        </td>
                        <td>{w.winners}</td>
                      </tr>
                    )
                  )}
                  </tbody>
                </table>
              </div>
              <div className="latestResultsTable2">
                <div className="latestResultsTableCaption">Random winners</div>
                <div className="latestResultsTable2Container">
                  {data && data.winners.map((w, i) => (
                    <div className="latestResultsTable2Item" key={i}>
                      <div className="latestResultsTable2ItemUser">
                        <div className="latestResultsTable2ItemFlag">{IsoToFlag(w.country)}</div>
                        <div className="latestResultsTable2ItemUserName">{w.username}</div>
                      </div>
                      <div className="latestResultsTable2ItemGame">
                        {w.game} draw winner
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="whyChooseUs">
        <div className="whyChooseUsCaption">Why Choose Us?</div>
        <div className="whyChooseUsDescription">As a game operator you can give your full focus on building your
          customer base and provide the support knowing that the game and winnings are all provided for you
          automatically
        </div>
        <div className="whyChooseUsBox">
          <div>
            <div style={{display: "flex"}}>
              <div className="whyChooseUsCard">
                <div className="whyCard whyCard1"/>
                <div className="whyCardDescription">
                  2.4 million lottery jackpots
                </div>
              </div>
              <div className="whyChooseUsCard">
                <div className="whyCard whyCard2"/>
                <div className="whyCardDescription">
                  Insured game up to
                  6.24 million euro
                </div>
              </div>
              <div className="whyChooseUsCardExtra">
                Winning as a service model only requires B2B clients to bring the clients and give support. Lucky Dozens
                provides everything else.
              </div>
            </div>
            <div style={{display: "flex"}}>
              <div className="whyChooseUsCard">
                <div className="whyCard whyCard3"/>
                <div className="whyCardDescription">
                  Instant payout <br/>
                  system
                </div>
              </div>
              <div className="whyChooseUsCard">
                <div className="whyCard whyCard4"/>
                <div className="whyCardDescription">
                  Winning as
                  a service
                </div>
              </div>
              <div className="whyChooseUsCard">
                <div className="whyCard whyCard5"/>
                <div className="whyCardDescription">
                  B2B Support
                </div>
              </div>
            </div>
          </div>
          <div className="whyBg"/>
        </div>
      </div>

      <div className="howItWorks">
        <div className="howItWorksCaption">How It Works</div>
        <div className="howItWorksDescription">By purchasing game tickets, B2B clients participates to 7 draws which all
          can provide winnings based on the winnings of other players. The Lucky Dozens game creates 12 random winners
          for each main draw winner. For example; when someone wins 2.4 million euro in a Main Draw, there are 12 other
          players winning 200,000 euros each, just by having active game ticket, no correct numbers needed.
        </div>
        <div className="howItWorksBg"/>
      </div>


      <div className="usersStat">
        <div className="usersStatContainer">
          <div style={{overflow: "hidden"}}>
            <div className="usersStatCaption">Our Users Around The World</div>
            <div className="usersStatDescription">We have since our start provided excitement for players all over the
              world. We are currently in 22 countries and all continents.
            </div>
            <div className="map"/>
            {data &&
            <div className="usersStatDataContainer">
              <div className="usersStatData">
                <div className="usersStatDataAmount">{data.totalWinners}</div>
                <div className="usersStatDataText">Total Winners</div>
              </div>
              <div className="usersStatData">
                <div className="usersStatDataAmount">{data.totalMainDrawWinners}</div>
                <div className="usersStatDataText">Total Main Draw Winners</div>
              </div>
              <div className="usersStatData">
                <div className="usersStatDataAmount">{data.totalLuckyDrawWinners}</div>
                <div className="usersStatDataText">Total Lucky Draw Winners</div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
      >
        <div className="modal">
          <video
            id="video"
            autoPlay={true}
            playsInline
            muted
            ref={(node) => {
              vidRef.current = node;
              vidRef.current && vidRef.current.play();
            }}
            onEnded={handleCloseModal}
            width={500} height={500}>
            <source width={500} height={500} src={"/videos/draw_" + number + ".mp4"} type="video/mp4"/>
          </video>
        </div>
      </Modal>

    </>
  )
}