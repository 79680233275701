import { jwt } from './jwt';
import { api } from './api';

import { API_URLS } from './apiUrls';

const BASIC_CODE = process.env.REACT_APP_BASIC_CODE;

export class Client {

  //Users
  auth2fa = ({ email, password }) =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=password&username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  refreshToken = () =>
    api.post(
      API_URLS.OAUTH.TOKEN,
      `grant_type=refresh_token&refresh_token=${jwt.refreshToken}`,
      {
        headers: {
          Authorization: `Basic ${BASIC_CODE}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

  //Users
  getCurrentUser = () => api.get(API_URLS.USERS.CURRENT);

  //Public
  getPublicGameData = () => api.get(API_URLS.PUBLIC.DATA);

  //SSE
  getStream = () => api.setStream(API_URLS.SSE.STREAM)

}

export const client = new Client();
