import React from "react";
import './Header.css';
import {OrangeButton} from "../TrayectoButton/OrangeButton";

export const Header = () => {
  return (
    <div className="header">
      <div className="headerInner">
        <div className="headerCaption">
          The Game where you can win even if you don’t
        </div>
        <div className="headerDescription">
          The next generation social game where every player can benefit the power of masses but individually and in
          privacy.
        </div>
      </div>
    </div>
  )
}